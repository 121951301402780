<h6>Add CPR to whitelist:</h6>
<div class="add-to-whitelist">
	<div class="d-flex flex-column w-100">
		<span>CPR:</span>
		<input
			type="text"
			[(ngModel)]="inputCpr"
			[class.error]="error"
			placeholder="CPR"
			class="input-whitelist"
		/>
	</div>
	<div class="d-flex flex-column w-100">
		<span>Name:</span>
		<input
			type="text"
			[(ngModel)]="inputName"
			placeholder="Name"
			class="input-whitelist"
			[class.error]="error"
		/>
	</div>

	<button
		type="button"
		class="btn btn-secondary btn-cancel"
		[disabled]="addingToWhitelist"
		(click)="addToWhitelist()"
	>
		Add to Whitelist
	</button>
</div>

<div class="table-body">
	<ng2-smart-table
		[settings]="settings"
		[source]="whiteList"
		(editConfirm)="onSaveConfirm($event)"
		(deleteConfirm)="onDeleteConfirm($event)"
	>
	</ng2-smart-table>
</div>
