<h1>Create Observation</h1>
<form (submit)="submitForm($event)" [formGroup]="observationForm" novalidate>
	<div class="flex-column">
		<div class="item-obs">
			<h6>Description:</h6>
			<textarea
				[(ngModel)]="description"
				id="description"
				class="form-control"
				name="description"
				formControlName="description"
				rows="10"
				cols="100"
			></textarea>
		</div>
		<div class="item-obs">
			<h6>User ID:</h6>
			<input
				[(ngModel)]="userIdInput"
				type="text"
				id="userId"
				name="userId"
				class="form-control"
				formControlName="userId"
			/>
		</div>
		<div class="item-obs">
			<h6>Type:</h6>
			<select [(ngModel)]="type" formControlName="type" name="types" id="types">
				<option value="1">Verification</option>
				<option value="2">Deposit</option>
				<option value="3">Withdrawal</option>
				<option value="4">Behavior</option>
			</select>
		</div>
	</div>
	<button
		type="button"
		type="submit"
		class="btn btn-ladda bth-submit"
		data-style="zoom-in"
		(click)="submitForm($event)"
	>
		Create Observation
	</button>
</form>
