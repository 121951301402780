import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../auth/auth.service';

import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { TransactionType } from '../../../shared/models/enums/transactionType';
import {
	AffiliateDTO,
	AffiliatePlayerDTO,
	EditAffiliateDTO,
} from '@app/pages/affiliates-managing/affiliates';

@Injectable({
	providedIn: 'root',
})
export class DataService {
	constructor(
		private httpClient: HttpClient,
		private auth: AuthService,
	) {}

	getUsers(): Observable<any> {
		return this.httpClient.get(`${environment.API_URL}/cms/account`, {
			headers: this.auth.createHeader(),
		});
	}

	getPlayers(key: string, value: string): Observable<any> {
		return this.httpClient.get(
			`${environment.API_URL}/cms/players/${key}/${value}/`,
			{ headers: this.auth.createHeader() },
		);
	}

	getUserInfo(id: string): Observable<any> {
		return this.httpClient.get(`${environment.API_URL}/cms/account/${id}`, {
			headers: this.auth.createHeader(),
		});
	}

	getPlayerInfo(id: string): Observable<any> {
		return this.httpClient.get(`${environment.API_URL}/cms/players/${id}/`, {
			headers: this.auth.createHeader(),
		});
	}

	getPlayerTransactions(id: string, body: object): Observable<any> {
		return this.httpClient.post(
			`${environment.API_URL}/cms/players/${id}/transactions`,
			body,
			{ headers: this.auth.createHeader() },
		);
	}

	getPlayerLimits(id: string): Observable<any> {
		return this.httpClient.get(
			`${environment.API_URL}/cms/players/${id}/limits`,
			{ headers: this.auth.createHeader() },
		);
	}

	getPlayerPendingLimits(id: string): Observable<any> {
		return this.httpClient.get(
			`${environment.API_URL}/cms/players/${id}/pendinglimits`,
			{ headers: this.auth.createHeader() },
		);
	}

	revalidateCPR(id: string): Observable<any> {
		return this.httpClient.put(
			`${environment.API_URL}/cms/players/revalidatecpr/${id}/`,
			{},
			{ headers: this.auth.createHeader() },
		);
	}

	getGames(): Observable<any> {
		return this.httpClient.get(`${environment.API_URL}/cms/games`, {
			headers: this.auth.createHeader(),
		});
	}

	getExcludedPlayers(): Observable<any> {
		return this.httpClient
			.get(`${environment.API_URL}/cms/players/excluded`, {
				headers: this.auth.createHeader(),
			})
			.pipe(
				map((resp) => {
					// return resp;
					return resp;
				}),
				catchError((error) => Observable.throw(error)),
			);
	}

	editExcludedUser(body: any): Observable<any> {
		return this.httpClient.put(
			`${environment.API_URL}/cms/players/excluded/update`,
			body,
			{ headers: this.auth.createHeader() },
		);
	}

	addGame(body: any): Observable<any> {
		return this.httpClient.post(
			`${environment.API_URL}/cms/games/create`,
			body,
			{ headers: this.auth.createHeader() },
		);
	}

	editGame(id: string, body: any): Observable<any> {
		return this.httpClient.post(
			`${environment.API_URL}/cms/games/edit/${id}`,
			body,
			{ headers: this.auth.createHeader() },
		);
	}

	addMoney(id: string, amount: number): Observable<any> {
		return this.httpClient.post(
			`${environment.API_URL}/cms/players/${id}/addmoney/`,
			{ Amount: amount },
			{ headers: this.auth.createHeader() },
		);
	}

	transferMoney(id: string, body: any): Observable<any> {
		return this.httpClient.post(
			`${environment.API_URL}/cms/players/${id}/transfer`,
			body,
			{ headers: this.auth.createHeader() },
		);
	}

	getSessions(getSessionParams: any): Observable<any> {
		const params = new HttpParams()
			.append('userid', getSessionParams.userId)
			.append('sessionid', getSessionParams.sessionid)
			.append('betid', getSessionParams.betid)
			.append('from', getSessionParams.from)
			.append('to', getSessionParams.to);

		return this.httpClient.get(`${environment.API_URL}/cms/sessions`, {
			headers: this.auth.createHeader(),
			params: params,
		});
	}

	enableGame(id: string, reason: string): Observable<any> {
		return this.httpClient.patch(
			`${environment.API_URL}/cms/games/enable/${id}`,
			{ ChangeReason: reason },
			{ headers: this.auth.createHeader() },
		);
	}

	enableAllGame(enable: boolean, reason: string): Observable<any> {
		return this.httpClient.patch(
			`${environment.API_URL}/cms/games/enable/all/${enable}`,
			{ ChangeReason: reason },
			{ headers: this.auth.createHeader() },
		);
	}

	editAlias(body: any): Observable<any> {
		return this.httpClient.put(
			`${environment.API_URL}/cms/players/alias`,
			body,
			{ headers: this.auth.createHeader() },
		);
	}

	activityPlayer(body: object): Observable<any> {
		return this.httpClient.patch(
			`${environment.API_URL}/cms/players/activity`,
			body,
			{ headers: this.auth.createHeader() },
		);
	}

	suspensionPlayer(body: object): Observable<any> {
		return this.httpClient.patch(
			`${environment.API_URL}/cms/players/suspension`,
			body,
			{ headers: this.auth.createHeader() },
		);
	}

	getWithdrawals(accepted: boolean, rejected: boolean): Observable<any> {
		return this.httpClient.get(
			`${environment.API_URL}/cms/withdrawals?accepted=${accepted}&rejected=${rejected}`,
			{ headers: this.auth.createHeader() },
		);
	}

	acceptWithdrawal(body: object): Observable<any> {
		return this.httpClient.post(
			`${environment.API_URL}/cms/withdrawals/accept`,
			body,
			{ headers: this.auth.createHeader() },
		);
	}

	getWallet(): Observable<any> {
		return this.httpClient.get(`${environment.API_URL}/api/health/wallet`, {
			headers: this.auth.createHeader(),
		});
	}

	getSignUpStatistic(from: Date, to: Date): Observable<any> {
		const params = new HttpParams()
			.append('from', from.toISOString())
			.append('to', to.toISOString());
		return this.httpClient.get(
			`${environment.API_URL}/api/health/getsignupstatistic`,
			{
				headers: this.auth.createHeader(),
				params: params,
			},
		);
	}

	getWalletStatistic(
		from: Date,
		to: Date,
		types: TransactionType[],
	): Observable<any> {
		let params = new HttpParams()
			.append('from', from.toISOString())
			.append('to', to.toISOString());
		types.forEach((type) => {
			params = params.append('transactionType', type.toString());
		});
		return this.httpClient.get(
			`${environment.API_URL}/api/health/getwalletstatistic`,
			{
				headers: this.auth.createHeader(),
				params: params,
			},
		);
	}

	GetAffiriatesStatisticsGeneral(): Observable<Object> {
		return this.httpClient.get(
			`${environment.API_URL}/cms/affiliates/statistic`,
			{ headers: this.auth.createHeader() },
		);
	}

	GetAffiliatesStatisticsPerMonth(date: Date): Observable<Object> {
		const params = new HttpParams().append('date', date.toISOString());
		return this.httpClient.get(
			`${environment.API_URL}/cms/affiliates/statistic`,
			{ headers: this.auth.createHeader(), params },
		);
	}

	GetAffiliateStatisticPerDays(
		date: Date,
		affilateId: string,
	): Observable<Object> {
		const params = new HttpParams()
			.append('date', date.toISOString())
			.append('id', affilateId);
		return this.httpClient.get(
			`${environment.API_URL}/cms/affiliates/details`,
			{ headers: this.auth.createHeader(), params },
		);
	}
}
