import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalDataSource } from 'ng2-smart-table';

export class WhitelistItem {
	cpr: string;
	name: string;
	transactionDate: Date;
	id: string;
}

@Component({
	selector: 'ngx-settings-cpr',
	templateUrl: './settings-cpr.component.html',
	styleUrls: ['./settings-cpr.component.scss'],
})
export class SettingsCprComponent implements OnInit {
	inputCpr = '';
	inputName = '';
	addingToWhitelist = false;
	error = false;
	settings = {
		noDataMessage: 'No data',
		edit: {
			editButtonContent: '<i class="nb-edit"></i>',
			saveButtonContent: '<i class="nb-checkmark"></i>',
			cancelButtonContent: '<i class="nb-close"></i>',
			confirmSave: true,
		},
		delete: {
			confirmDelete: true,
			deleteButtonContent: '<i class="nb-trash"></i>',
		},
		actions: {
			add: false,
			edit: true,
			delete: true,
		},
		columns: {
			cpr: {
				title: 'CPR',
				type: 'string',
				editable: true,
				filter: true,
			},
			name: {
				title: 'Name',
				type: 'string',
				filter: true,
				editable: true,
			},
			id: {
				title: 'ID',
				type: 'string',
				filter: false,
				editable: false,
			},
			transactionDate: {
				title: 'Transaction Date',
				type: 'string',
				filter: true,
				editable: false,
			},
		},
	};

	whiteList: LocalDataSource = new LocalDataSource();

	constructor(private httpClient: HttpClient) {}

	ngOnInit(): void {
		this.getWhitelistedCprs();
	}

	createHeader() {
		return new HttpHeaders()
			.set('Token', this.getToken())
			.set('Access-Control-Allow-Origin', '*');
	}

	getToken() {
		return window.localStorage.getItem(environment.token_name);
	}

	addToWhitelist() {
		if (
			!this.inputName ||
			!this.inputCpr ||
			this.inputCpr.length < 10 ||
			this.inputName.length < 3
		) {
			this.error = true;
			return;
		}
		this.error = false;
		this.addingToWhitelist = true;
		this.httpClient
			.post(
				environment.API_URL + '/cms/settings/whitelist/cpr',
				{
					cpr: this.inputCpr,
					name: this.inputName,
				},
				{
					headers: this.createHeader(),
				},
			)
			.subscribe((data) => {
				this.addingToWhitelist = false;
				this.whiteList.prepend(data);
				this.whiteList.refresh();
				this.inputName = '';
				this.inputCpr = '';
			});
	}

	getWhitelistedIps() {
		this.httpClient
			.get(environment.API_URL + '/cms/settings/whitelist/ip', {
				headers: this.createHeader(),
			})
			.subscribe((data) => {
				console.log(data, 'IPS-SETTING');
			});
	}

	getWhitelistedCprs() {
		this.httpClient
			.get(environment.API_URL + '/cms/whitelist/cprs', {
				headers: this.createHeader(),
			})
			.subscribe((data: Array<WhitelistItem>) => {
				this.whiteList.load(data);
			});
	}

	onSaveConfirm(data: {
		newData: WhitelistItem;
		confirm: any;
		data: WhitelistItem;
	}) {
		const body = data.newData;
		this.httpClient
			.put(environment.API_URL + '/cms/whitelist/cpr/edit', body, {
				headers: this.createHeader(),
			})
			.subscribe((whiteItem: WhitelistItem) => {
				this.whiteList.update(data.data, whiteItem);
				this.whiteList.refresh();
				data.confirm.resolve();
			});
	}

	async onDeleteConfirm(event) {
		if (window.confirm('Are you sure you want to delete?')) {
			this.httpClient
				.delete(
					environment.API_URL +
						`/cms/whitelist/cpr/delete/?id=${event.data.id}`,
					{
						headers: this.createHeader(),
					},
				)
				.subscribe((whiteItem: WhitelistItem) => {
					this.whiteList.remove(whiteItem);
					this.whiteList.refresh();
					event.confirm.resolve();
				});
		} else {
			event.confirm.reject();
		}
	}
}
