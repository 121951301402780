import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
	selector: 'ngx-information-modal',
	templateUrl: './information-modal.component.html',
	styleUrls: ['./information-modal.component.scss'],
})
export class InformationModalComponent implements OnInit {
	@Input() title: string;

	constructor(protected ref: NbDialogRef<InformationModalComponent>) {}

	dismiss() {
		this.ref.close();
	}

	ngOnInit(): void {}
}
