import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
	UrlTree,
} from '@angular/router';
import { AuthService } from '../../pages/auth/auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(
		private readonly authService: AuthService,
		private readonly router: Router,
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean | UrlTree {
		return this.authService.isLoggedIn()
			? true
			: this.router.createUrlTree(['/'], {
					queryParams: { returnUrl: state.url },
				});
	}
}
