import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
	DepositLimit,
	Observation,
	Player,
	PlayerDetails,
	PlayerFreespin,
	PlayerLimit,
	Transaction,
} from './players';
import Spin from '../../shared/interfaces/Spin';
import { AttentionLevelUserDto } from '../attention-level/attention-level';
import { UserRole } from './modals/change-user-role-modal/user-role';

@Injectable({
	providedIn: 'root',
})
export class PlayersService {
	aliasUpdating: Subject<string> = new Subject();
	attentionUpdating: Subject<string> = new Subject();
	emailUpdating: Subject<string> = new Subject();
	newsletterUpdating: Subject<string> = new Subject();
	roleUpdating: Subject<string> = new Subject();
	blockedDepositLimitIncrease: Subject<boolean> = new Subject();

	constructor(private readonly httpClient: HttpClient) {}

	getPlayers(data: { key: string; value: string }): Observable<Player[]> {
		return this.httpClient.get<Player[]>(
			`${environment.API_URL}/cms/players/${data.key}/${data.value}/`,
		);
	}

	getPlayerDetails(profileId: string): Observable<PlayerDetails> {
		return this.httpClient.get<PlayerDetails>(
			`${environment.API_URL}/cms/players/${profileId}/`,
		);
	}

	getDeposits(
		profileId: string,
		data: { Skip: number; Limit: number },
	): Observable<any> {
		return this.httpClient.post(
			`${environment.API_URL}/cms/players/${profileId}/deposits-transactions`,
			data,
		);
	}

	getWithdrawals(
		profileId: string,
		data: { Skip: number; Limit: number },
	): Observable<any> {
		return this.httpClient.post(
			`${environment.API_URL}/cms/players/${profileId}/withdrawals-transactions`,
			data,
		);
	}

	getPlayerTransactions(
		data: { Skip: number; Limit: number },
		profileId: string,
	): Observable<Transaction[]> {
		return this.httpClient.post<Transaction[]>(
			`${environment.API_URL}/cms/players/${profileId}/games-transactions`,
			data,
		);
	}

	getPlayerLimits(profileId: string): Observable<PlayerLimit[]> {
		return this.httpClient.get<PlayerLimit[]>(
			`${environment.API_URL}/cms/players/${profileId}/limits`,
		);
	}

	getPlayerObservations(userId: string): Observable<Observation[]> {
		return this.httpClient.get<Observation[]>(
			`${environment.API_URL}/cms/observations/${userId}`,
		);
	}

	updatePlayerObservation(
		observationId: string,
		data: { type: number; description: string },
	): Observable<Observation> {
		return this.httpClient.patch<Observation>(
			`${environment.API_URL}/cms/observations/edit/${observationId}`,
			data,
		);
	}

	createPlayerObservation(data: Observation): Observable<{}> {
		return this.httpClient.post(
			`${environment.API_URL}/cms/observations/create`,
			data,
		);
	}

	getPlayerPendingLimits(profileId: string): Observable<PlayerLimit[]> {
		return this.httpClient.get<PlayerLimit[]>(
			`${environment.API_URL}/cms/players/${profileId}/pendinglimits`,
		);
	}

	revalidateCpr(profileId: string): Observable<{}> {
		return this.httpClient.put<{}>(
			`${environment.API_URL}/cms/players/revalidatecpr/${profileId}/`,
			{},
		);
	}

	revalidateCprAndgetData(profileId: string): Observable<{}> {
		return this.httpClient.get<{}>(
			`${environment.API_URL}/cms/players/revalidatecpr/${profileId}/`,
			{},
		);
	}

	checkPep(profileId: string): Observable<{}> {
		return this.httpClient.put<{}>(
			`${environment.API_URL}/cms/players/verifyPep/${profileId}/`,
			{},
		);
	}

	updateActivity(data: {
		ChangeReason: string;
		UserId: string;
	}): Observable<{}> {
		return this.httpClient.patch<{}>(
			`${environment.API_URL}/cms/players/activity`,
			data,
		);
	}

	updateSuspension(data: {
		ChangeReason: string;
		UserId: string;
	}): Observable<{}> {
		return this.httpClient.patch<{}>(
			`${environment.API_URL}/cms/players/suspension`,
			data,
		);
	}

	updateCpr(data: { ChangeReason: string; UserId: string }): Observable<{}> {
		return this.httpClient.patch<{}>(
			`${environment.API_URL}/cms/players/cpr`,
			data,
		);
	}

	updateTransfer(
		data: {
			amount: number;
			reason: string;
		},
		profileId: string,
	): Observable<{}> {
		return this.httpClient.post<{}>(
			`${environment.API_URL}/cms/players/${profileId}/transfer`,
			data,
		);
	}

	updateAlias(data: {
		alias: string;
		changeReason: string;
		userId: string;
	}): Observable<string> {
		return this.httpClient.put<string>(
			`${environment.API_URL}/cms/players/alias`,
			data,
		);
	}

	updateAttention(data: {
		changeReason: string;
		attention: number;
		userId: string;
	}): Observable<string> {
		return this.httpClient.put<string>(
			`${environment.API_URL}/cms/players/attention`,
			data,
		);
	}

	getUsersByAttention(attention: Number): Observable<AttentionLevelUserDto[]> {
		const params = new HttpParams().set('attention', attention.toString());
		return this.httpClient.get<AttentionLevelUserDto[]>(
			environment.API_URL + `/cms/players`,
			{
				params: params,
			},
		);
	}

	updateEmail(data: {
		email: string;
		changeReason: string;
		userId: string;
	}): Observable<string> {
		return this.httpClient.put<string>(
			`${environment.API_URL}/cms/players/email`,
			data,
		);
	}

	updateNewsletter(data: {
		newsletter: boolean;
		changeReason: string;
		userId: string;
	}): Observable<string> {
		return this.httpClient.put<string>(
			`${environment.API_URL}/cms/players/newsletter`,
			data,
		);
	}

	private convertTransactionTypeToString(type: number): string {
		let transactionType = '';
		switch (type) {
			case 0:
				transactionType = 'Deposited to game account';
				break;
			case 1:
				transactionType = '';
				break;
			case 2:
				transactionType = 'Refunded';
				break;
			case 3:
				transactionType = '';
				break;
			case 4:
				transactionType = 'Paid to Nemkonto';
				break;
			case 22:
				transactionType = 'Jackpot';
				break;
			case 30:
				transactionType = 'Transferred to player';
				break;
			case 31:
				transactionType = 'From Bingo.dk';
				break;
			default:
				transactionType = '';
				break;
		}

		return transactionType;
	}

	getFreespins(userId: string, spent: boolean): Observable<PlayerFreespin[]> {
		const headers = new HttpHeaders({
			Token: localStorage.getItem('auth_token'),
		});
		return this.httpClient.get<PlayerFreespin[]>(
			`${environment.API_URL}/cms/freespins/${userId}/${spent}/`,
			{ headers },
		);
	}

	deleteFreespins(freespinId: string): Observable<{}> {
		return this.httpClient.delete<{}>(
			`${environment.API_URL}/cms/freespins/${freespinId}/`,
			{},
		);
	}

	addFreespins(
		gameId: number,
		userId: string,
		freespins: number,
	): Observable<{}> {
		const headers = new HttpHeaders({
			Token: localStorage.getItem('auth_token'),
		});
		return this.httpClient.post<{}>(
			`${environment.API_URL}/cms/freespins`,
			{ gameId, userId, freespins },
			{ headers },
		);
	}

	withdraw(userId: string, amount: number): Observable<{}> {
		const headers = new HttpHeaders({
			Token: localStorage.getItem('auth_token'),
		});
		return this.httpClient.post<{}>(
			`${environment.API_URL}/cms/payment/withdraw?userId=${userId}&amount=${amount}`,
			{},
			{ headers },
		);
	}

	getUserRoles(): Observable<UserRole[]> {
		return this.httpClient.get<UserRole[]>(
			`${environment.API_URL}/cms/players/roles`,
		);
	}

	changeUserRole(
		userId: string,
		changeReason: string,
		roleId: number,
	): Observable<{}> {
		const headers = new HttpHeaders({
			Token: localStorage.getItem('auth_token'),
		});

		return this.httpClient.put(
			`${environment.API_URL}/cms/players/role`,
			{ userId: userId, changeReason: changeReason, roleId: roleId },
			{ headers },
		);
	}

	editDepositLimit(depositLimit: DepositLimit): Observable<DepositLimit> {
		const headers = new HttpHeaders({
			Token: localStorage.getItem('auth_token'),
		});

		return this.httpClient.put<DepositLimit>(
			`${environment.API_URL}/cms/payment/limits/${depositLimit.Id}`,
			depositLimit,
			{ headers },
		);
	}

	changeBlockedDepositLimitIncrease(
		userId: string,
		blocked: boolean,
	): Observable<any> {
		const headers = new HttpHeaders({
			Token: localStorage.getItem('auth_token'),
		});

		return this.httpClient.put(
			`${environment.API_URL}/cms/players/${userId}/blocked-limits-increase?blocked=${blocked}`,
			headers,
		);
	}
}
