<nb-card-body
	[nbSpinner]="nbSpinner"
	nbSpinnerSize="large"
	nbSpinnerStatus="primary"
>
	<ng2-smart-table [settings]="settings" [source]="affiliatesInfoPerDays">
	</ng2-smart-table>

	<ng2-smart-table [settings]="settingsTotal" [source]="affiliatesInfoTotal">
	</ng2-smart-table>
</nb-card-body>
