import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { catchError, finalize, map } from 'rxjs/operators';

export interface SessionData {
	name: string;
	authToken: string;
	issuedOn: string;
	expiresOn: string;
	userRole: string;
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private httpClient: HttpClient, private router: Router) {}

	nameToken: string = 'userToken';
	userName: string = 'userName';
	userRole: string = 'userRole';

	private isAuthenticated: boolean = false;

	login(body: { email: string; password: string }): Observable<any> {
		localStorage.removeItem('userRole');
		return this.httpClient.post(environment.API_URL + '/cms/auth/login', body);
	}

	requestPassword(body: { email: string }): Observable<any> {
		return this.httpClient.post(
			environment.API_URL + '/cms/account/password/reset/',
			body,
		);
	}

	resetPassword(body: {
		VerificationIdentifier: string;
		NewPassword: string;
	}): Observable<any> {
		return this.httpClient.put(
			environment.API_URL + '/cms/account/verify/password/',
			body,
		);
	}

	setSession(data: SessionData) {
		const expiresOn = moment(data.expiresOn).valueOf();
		localStorage.setItem('auth_token', data.authToken);
		localStorage.setItem('expires_on', JSON.stringify(expiresOn));
		localStorage.setItem('userName', data.name);

		localStorage.setItem('userRole', 'admin');
	}

	isLoggedIn() {
		return moment().isBefore(this.getExpiration());
	}

	getAuthToken() {
		return localStorage.getItem('auth_token');
	}
	getUserRole() {
		return localStorage.getItem('userRole');
	}

	getExpiration() {
		const expiration = localStorage.getItem('expires_on');
		const expiresAt = JSON.parse(expiration);

		return moment(expiresAt);
	}

	logout() {
		return this.httpClient
			.post(environment.API_URL + '/cms/auth/logout', {})
			.pipe(
				map(() => {
					this.removeAuthData();
					return true;
				}),
				finalize(() => this.removeAuthData()),
				catchError(() => {
					return of(false);
				}),
			);
	}

	removeAuthData() {
		localStorage.removeItem('auth_token');
		localStorage.removeItem('expires_on');
		localStorage.removeItem('userName');
		localStorage.removeItem('userEmail');
		localStorage.removeItem('userRole');
	}

	// signIn(body: User): Observable<any> {
	//   return this.http.post(`${environment.API_URL}/cms/auth/login`, body);
	// }

	// signOut(): Observable<any> {
	//   return this.http.post(`${API_URL}/cms/auth/logout`, null, {
	//     headers: this.createHeader(),
	//   });
	// }

	// signUp(body: User, validate: boolean): Observable<any> {
	//   if (validate) {
	//     return this.http.post(
	//       `${API_URL}/cms/account/create?validateOnly=true`,
	//       body,
	//       { headers: this.createHeader() }
	//     );
	//   }
	//   return this.http.post(`${API_URL}/cms/account/create`, body, {
	//     headers: this.createHeader(),
	//   });
	// }

	// removeById(id: string): Observable<any> {
	//   return this.http.post(`${API_URL}/cms/account/delete/${id}`, null, {
	//     headers: this.createHeader(),
	//   });
	// }

	// editById(body: object, id: string): Observable<any> {
	//   return this.http.put(`${API_URL}/cms/account/edit/${id}`, body, {
	//     headers: this.createHeader(),
	//   });
	// }

	// resetPassword(body: object): Observable<any> {
	//   return this.http.post(`${API_URL}/cms/account/password/reset/`, body);
	// }

	// login(userToken: string, userName?: string) {
	//   this.isAuthenticated = true;
	//   window.localStorage.setItem(this.nameToken, userToken);
	//   window.localStorage.setItem(this.userName, userName);
	//   this.router.navigate(["dashboard"]);
	// }

	// verifyUser(body) {
	//   this.router.navigate(["dashboard"]);
	//   return this.http.put(`${API_URL}/cms/account/verify`, body);
	// }

	// newUserPassword(body) {
	//   //   this.router.navigate(['login']);
	//   return this.http.put(`${API_URL}/cms/account/verify/password/`, body);
	// }

	// logout() {
	//   this.isAuthenticated = false;
	//   window.localStorage.removeItem(this.nameToken);
	//   window.localStorage.removeItem(this.userName);
	//   this.router.navigate(["/login"]);
	// }

	// isLoggedIn(): boolean {
	//   return !!this.getToken();
	// }

	// getToken() {
	//   return window.localStorage.getItem(this.nameToken);
	// }

	// createHeader() {
	//   return new HttpHeaders()
	//     .set("Token", this.getToken())
	//     .set("Access-Control-Allow-Origin", "*");
	// }
}
