import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

import { User } from '@app/shared/models/user.models';
import { environment } from '../../../../environments/environment';
import { SessionData } from '@app/pages/auth/auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private http: HttpClient, private router: Router) {}

	nameToken: string = 'auth_token';
	userName: string = 'userName';
	private isAuthenticated: boolean = false;

	signIn(body: User): Observable<any> {
		return this.http.post(`${environment.API_URL}/cms/auth/login`, body);
	}

	signOut(): Observable<any> {
		return this.http.post(`${environment.API_URL}/cms/auth/logout`, null, {
			headers: this.createHeader(),
		});
	}

	signUp(body: User, validate: boolean): Observable<any> {
		if (validate) {
			return this.http.post(
				`${environment.API_URL}/cms/account/create?validateOnly=true`,
				body,
				{ headers: this.createHeader() },
			);
		}
		return this.http.post(`${environment.API_URL}/cms/account/create`, body, {
			headers: this.createHeader(),
		});
	}

	removeById(id: string): Observable<any> {
		return this.http.post(
			`${environment.API_URL}/cms/account/delete/${id}`,
			null,
			{
				headers: this.createHeader(),
			},
		);
	}

	editById(body: object, id: string): Observable<any> {
		return this.http.put(
			`${environment.API_URL}/cms/account/edit/${id}`,
			body,
			{
				headers: this.createHeader(),
			},
		);
	}

	resetPassword(body: object): Observable<any> {
		return this.http.post(
			`${environment.API_URL}/cms/account/password/reset/`,
			body,
		);
	}

	login(userToken: string, userName?: string) {
		this.isAuthenticated = true;
		window.localStorage.setItem(this.nameToken, userToken);
		window.localStorage.setItem(this.userName, userName);
		this.router.navigate(['dashboard']);
	}

	verifyUser(body): Observable<SessionData> {
		this.router.navigate(['dashboard']);
		return this.http.put<SessionData>(
			`${environment.API_URL}/cms/account/verify`,
			body,
		);
	}

	newUserPassword(body) {
		//   this.router.navigate(['login']);
		return this.http.put(
			`${environment.API_URL}/cms/account/verify/password/`,
			body,
		);
	}

	logout(navigate = true) {
		this.isAuthenticated = false;
		window.localStorage.removeItem(this.nameToken);
		window.localStorage.removeItem(this.userName);
		navigate && this.router.navigate(['/login']);
	}

	isLoggedIn(): boolean {
		return !!this.getToken();
	}

	getToken() {
		return window.localStorage.getItem(this.nameToken);
	}

	createHeader() {
		return new HttpHeaders()
			.set('Token', this.getToken())
			.set('Access-Control-Allow-Origin', '*');
	}

	/*  createHeader() {
     return new HttpHeaders()
     .set('Token', this.getToken())
     .set('Access-Control-Allow-Origin', '*')
     }*/

	/*  createHeader() {
     let headers: HttpHeaders = new HttpHeaders();
     headers.append('Access-Control-Allow-Origin', '*');
     headers.append('Token', this.getToken());
     return headers;
     }*/
}
