import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NbButtonModule, NbCardModule, NbTreeGridModule } from '@nebular/theme';
import { ThemeModule } from '../../../@theme/theme.module';
import { InformationModalComponent } from './information-modal.component';

@NgModule({
	imports: [NbCardModule, NbTreeGridModule, ThemeModule, NbButtonModule],
	declarations: [InformationModalComponent],
	entryComponents: [InformationModalComponent],
})
export class InformationModalModule {}
