import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class MetadataService {
	constructor(
		private title: Title,
		private meta: Meta,
	) {}

	updateTitle(title: string): void {
		this.title.setTitle(title);
		this.meta.updateTag({ property: 'og:title', content: title });
	}

	updateDescription(desc: string): void {
		this.meta.updateTag({ name: 'description', content: desc });
		this.meta.updateTag({ property: 'og:description', content: desc });
	}

	updateKeywords(keywords: string): void {
		this.meta.updateTag({ name: 'keywords', content: keywords });
	}
}
