import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './shared/guards/auth-guard';

export const routes: Routes = [
	{
		path: 'pages',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/pages.module').then((m) => m.PagesModule),
	},
	{
		path: 'auth',
		// canActivate: [ThemeGuard],
		loadChildren: () =>
			import('./pages/auth/auth.module').then((m) => m.AuthModule),
	},
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'auth/login',
	},
	{ path: '**', redirectTo: 'pages/dashboard' },
];

const config: ExtraOptions = {
	useHash: false,
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
