import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
	NbMediaBreakpointsService,
	NbMenuService,
	NbSidebarService,
	NbThemeService,
	NB_WINDOW,
} from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { AnalyticsService, LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter, finalize } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { RippleService } from '../../../@core/utils/ripple.service';
import { CurrentThemeService } from '../../../@core/utils/theme.service';
import { AuthService } from '../../../pages/auth/auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'ngx-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();
	public materialTheme$: Observable<boolean>;
	userPictureOnly: boolean = false;
	user: any;

	themes = [
		{
			value: 'default',
			name: 'Light',
		},
		{
			value: 'dark',
			name: 'Dark',
		},
		{
			value: 'cosmic',
			name: 'Cosmic',
		},
		{
			value: 'corporate',
			name: 'Corporate',
		},
		{
			value: 'material-light',
			name: 'Material Light',
		},
		{
			value: 'material-dark',
			name: 'Material Dark',
		},
	];

	currentTheme = 'default';

	userMenu = [{ title: 'Reset password' }, { title: 'Sign out' }];

	userName = '';

	public constructor(
		private sidebarService: NbSidebarService,
		private menuService: NbMenuService,
		private themeService: NbThemeService,
		private userService: UserData,
		private layoutService: LayoutService,
		private breakpointService: NbMediaBreakpointsService,
		private rippleService: RippleService,
		private analytics: AnalyticsService,
		private currentThemeService: CurrentThemeService,
		@Inject(NB_WINDOW) private window,
		private readonly authService: AuthService,
		private readonly router: Router,
	) {
		this.materialTheme$ = new Observable((subscriber) => {
			const themeName: string = this.currentThemeService.getCurrentTheme();

			subscriber.next(themeName.startsWith('material'));
		});
	}

	ngOnInit() {
		this.currentTheme = this.themeService.currentTheme;

		this.userService
			.getUsers()
			.pipe(takeUntil(this.destroy$))
			.subscribe((users: any) => (this.user = users.nick));

		const { xl } = this.breakpointService.getBreakpointsMap();
		this.themeService
			.onMediaQueryChange()
			.pipe(
				map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
				takeUntil(this.destroy$),
			)
			.subscribe(
				(isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl),
			);

		this.themeService
			.onThemeChange()
			.pipe(
				map(({ name }) => name),
				takeUntil(this.destroy$),
			)
			.subscribe((themeName) => {
				this.currentTheme = themeName;
				this.rippleService.toggle(themeName?.startsWith('material'));
			});

		this.menuService
			.onItemClick()
			.pipe(map(({ item: { title } }) => title))
			.subscribe((title) => {
				if (title === 'Sign out') {
					this.logout();
				}
				if (title === 'Reset password') {
					const userEmail = localStorage.getItem('userEmail');
					this.router.navigate(['auth', 'request-password'], {
						queryParams: { userEmail: userEmail || '' },
					});
				}
			});

		const userName = localStorage.getItem('userName');
		if (userName) {
			this.userName = userName;
		}
	}

	logout(): void {
		this.authService
			.logout()
			.pipe(
				finalize(() => {
					this.router.navigate(['auth', 'login']);
				}),
			)
			.subscribe(() => {
				return;
			});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	changeTheme(themeName: string) {
		this.currentThemeService.setCurrentTheme(themeName);
		this.themeService.changeTheme(themeName);

		this.materialTheme$ = new Observable((subscriber) => {
			subscriber.next(
				this.currentThemeService.getCurrentTheme().startsWith('material'),
			);
		});
	}

	toggleSidebar(): boolean {
		this.sidebarService.toggle(true, 'menu-sidebar');
		this.layoutService.changeLayoutSize();

		return false;
	}

	navigateHome() {
		this.menuService.navigateHome();
		return false;
	}

	startSearch() {
		this.analytics.trackEvent('startSearch');
	}

	trackEmailClick() {
		this.analytics.trackEvent('clickContactEmail', 'click');
	}
}
