import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { DataService } from '@app/shared/services/data/data.service';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';

@Component({
	selector: 'ngx-affilate-singple-player',
	templateUrl: './affilate-singple-player.component.html',
	styleUrls: ['./affilate-singple-player.component.scss'],
})
export class AffilateSingplePlayerComponent implements OnInit {
	nbSpinner = false;
	affiliatesInfoPerDays: LocalDataSource = new LocalDataSource();
	affiliatesInfoTotal: LocalDataSource = new LocalDataSource();
	settingsTotal = {
		noDataMessage: 'No data',
		actions: {
			add: false,
			edit: false,
			delete: false,
		},
		hideSubHeader: true,
		columns: {
			date: {
				title: '',
				type: 'string',
				editable: false,
				valuePrepareFunction: () => `Total`,
			},
			balance: {
				title: 'Balance',
				type: 'number',
				filter: false,
				editable: false,
			},
			bets: {
				title: 'Bets',
				type: 'number',
				filter: false,
				editable: false,
			},
			deposits: {
				title: 'Deposits',
				type: 'number',
				editable: false,
				filter: false,
			},
			depositors: {
				title: 'Depositors',
				type: 'number',
				editable: false,
				filter: false,
			},
			signups: {
				title: 'Signups',
				type: 'string',
				filter: false,
				editable: false,
			},
			withdrawals: {
				title: 'Withdrawals',
				type: 'number',
				filter: false,
				editable: false,
			},
			winnings: {
				title: 'Winnings',
				type: 'number',
				filter: false,
				editable: false,
			},
		},
	};

	settings = {
		noDataMessage: 'No data',
		add: {
			addButtonContent: '<i class="nb-plus"></i>',
			createButtonContent: '<i class="nb-checkmark"></i>',
			cancelButtonContent: '<i class="nb-close"></i>',
			confirmCreate: true,
		},
		edit: {
			editButtonContent: '<i class="nb-edit"></i>',
			saveButtonContent: '<i class="nb-checkmark"></i>',
			cancelButtonContent: '<i class="nb-close"></i>',
			confirmSave: true,
		},
		actions: {
			add: false,
			edit: false,
			delete: false,
		},
		columns: {
			date: {
				title: 'Day',
				type: 'string',
				editable: false,
				valuePrepareFunction: (value: Date) =>
					new Date(value).getDate() + '-step',
			},
			balance: {
				title: 'Balance',
				type: 'html',
				valuePrepareFunction: (value) => this.prepareMoneyValueForTable(value),
				filter: true,
				editable: false,
			},
			bets: {
				title: 'Bets',
				type: 'html',
				valuePrepareFunction: (value) => this.prepareMoneyValueForTable(value),
				filter: true,
				editable: false,
			},
			deposits: {
				title: 'Deposits',
				type: 'html',
				valuePrepareFunction: (value) => this.prepareMoneyValueForTable(value),
				editable: false,
				filter: true,
			},
			depositors: {
				title: 'Depositors',
				type: 'number',
				editable: false,
				filter: true,
			},
			signups: {
				title: 'Signups',
				type: 'string',
				filter: true,
				editable: false,
			},
			withdrawals: {
				title: 'Withdrawals',
				type: 'html',
				valuePrepareFunction: (value) => this.prepareMoneyValueForTable(value),
				filter: true,
				editable: false,
			},
			winnings: {
				title: 'Winnings',
				type: 'html',
				valuePrepareFunction: (value) => this.prepareMoneyValueForTable(value),
				filter: true,
				editable: false,
			},
		},
	};

	constructor(
		private dataService: DataService,
		private route: ActivatedRoute,
		private readonly currencyPipe: CurrencyPipe,
	) {}

	ngOnInit(): void {
		this.dataService
			.GetAffiliateStatisticPerDays(
				new Date(),
				this.route.snapshot.params['id'],
			)
			.subscribe(
				(data: {
					data: Array<{
						affiliateId: number;
						balance: number;
						bets: number;
						date: Date;
						depositors: number;
						deposits: number;
						name: string;
						signups: number;
						winnings: number;
						withdrawals: number;
					}>;
					total: {
						affiliateId: number;
						balance: number;
						bets: number;
						date: Date;
						depositors: number;
						deposits: number;
						name: null;
						signups: number;
						winnings: number;
						withdrawals: number;
					};
				}) => {
					this.affiliatesInfoTotal.load([data.total]);
					this.affiliatesInfoPerDays.load(data.data);
				},
			);
	}

	prepareMoneyValueForTable(value: number) {
		return (
			'<div class="cell_right">' +
			`${this.currencyPipe.transform(value, 'DDK', ' ')} kr` +
			'</div>'
		);
	}
}
