import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayersService } from '@app/pages/players/players.service';

@Component({
	selector: 'ngx-player-observation-create',
	templateUrl: './player-observation-create.component.html',
	styleUrls: ['./player-observation-create.component.scss'],
})
export class PlayerObservationCreateComponent implements OnInit {
	type = 1;
	description = '';
	userIdInput: string;

	// Form
	observationForm: FormGroup;

	constructor(
		private activatedRoute: ActivatedRoute,
		private readonly playersService: PlayersService,
		private readonly router: Router,
	) {}

	ngOnInit(): void {
		this.observationForm = new FormGroup({
			description: new FormControl('', [Validators.required]),
			type: new FormControl('', [Validators.required]),
			userId: new FormControl('', [Validators.required]),
		});
		this.activatedRoute.params.subscribe((params) => {
			this.userIdInput = params['id'];
		});
	}

	submitForm(event) {
		event.preventDefault();
		const body = {
			description: this.observationForm.get('description').value,
			type: this.observationForm.get('type').value,
			userId: this.observationForm.get('userId').value,
		};

		this.playersService
			.createPlayerObservation(body)
			.subscribe(async (data) => {
				await this.router.navigate([
					'pages',
					'spillere-sog',
					this.userIdInput,
					'observations',
				]);
			});
	}
}
