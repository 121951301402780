import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../pages/auth/auth.service';
import { EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		public readonly authService: AuthService,
		private readonly router: Router,
	) {}

	private addToken(
		request: HttpRequest<unknown>,
		token: string,
	): HttpRequest<unknown> {
		return request.clone({
			setHeaders: {
				token,
			},
		});
	}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		// add authorization header with auth token if available
		if (this.authService.isLoggedIn()) {
			request = this.addToken(request, this.authService.getAuthToken());
		}

		return next.handle(request).pipe(
			catchError((error) => {
				if (error instanceof HttpErrorResponse && error.status === 401) {
					this.authService.logout();
					this.router.navigate(['auth', 'login']);
					return EMPTY;
				} else {
					return throwError(error);
				}
			}),
		);
	}
}
